import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/Post.js";
import { Img } from '../../src/components/';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Introduction`}</h2>
    <p>{`A couple weeks back, I posted a `}<a parentName="p" {...{
        "href": "https://gopistola.typeform.com/to/osVw3W"
      }}>{`typeform survey`}</a>{` to several Strava and Zwift user groups on Facebook, reddit, and a couple cycling-related channels in Slack communities I belong to. It was the first outreach-related step in the process of deciding what Pistola should be aiming to do. The goal the survey was to learn about how these folks use Strava. I wanted to know what they like and dislike about it, and what their expectations were for future updates and changes to the product. I also asked some prying questions about what they pay for Strava (if anything), and whether or not they were satisfied with the product.`}</p>
    <p>{`It's worth noting that in crafting the survey, I ran into a few difficulties. My first draft survey was directly only at people who `}<strong parentName="p">{`ride indoors`}</strong>{` (with `}<a parentName="p" {...{
        "href": "https://zwift.com/"
      }}>{`Zwift`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.onepeloton.com/"
      }}>{`Peleton`}</a>{` or something similar). After some deliberation, I decided that I was interested in hearing from anyone who is interested in recording, viewing, and analyzing their workouts with an app or service. Zwift does have a large and growing audience, but I felt it might be too limiting to focus on just Zwift users, at least initially. So in the end I decided to shelf that survey, and start by asking any Strava user for feedback on product use.`}</p>
    <h2>{`Results (n=91) and my analysis`}</h2>
    <p>{`First of all, thank you to anyone who took or shared the survey! I can't tell you how much of a relief it was to see responses rolling in. What follows are my takeaways after reading through the 91 responses to the survey that I received.`}</p>
    <h3>{`About how many miles a year do you ride?`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Count:`}</strong>{` 86 responses total (some responders use strava for running rather than riding!)`}</li>
      <li parentName="ul"><strong parentName="li">{`High:`}</strong>{` 12,000mi/yr`}</li>
      <li parentName="ul"><strong parentName="li">{`Low:`}</strong>{` 40mi/yr`}</li>
      <li parentName="ul"><strong parentName="li">{`Average:`}</strong>{` 3221mi/yr`}</li>
    </ul>
    <h3>{`About how any miles a year do you run?`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Count:`}</strong>{` 22 responses total (again, some responders do not cycle, they just run, and vice versa)`}</li>
      <li parentName="ul"><strong parentName="li">{`High:`}</strong>{` 2400mi/yr`}</li>
      <li parentName="ul"><strong parentName="li">{`Low:`}</strong>{` 2mi/yr`}</li>
      <li parentName="ul"><strong parentName="li">{`Average:`}</strong>{` 663.5mi/yr`}</li>
    </ul>
    <h3>{`What Strava could be doing better`}</h3>
    <p>{`Athletes don’t like strava’s recent removal of bluetooth support. Sometime last year, Strava removed support for `}<a parentName="p" {...{
        "href": "https://support.strava.com/hc/en-us/articles/360032979131-Removing-Direct-Sensor-Pairing-from-the-Strava-Mobile-App"
      }}>{`bluetooth sensors`}</a>{` in their mobile app. This stems from reliability issues with bluetooth sensors.`}</p>
    <p>{`In a past life, spent `}<em parentName="p">{`quite a bit`}</em>{` of time working on an app to collect data from dozens of BLE sensors at a time. Let me be the first to tell you - I'm sympathetic to their engineers here. Bluetooth sensors are a pain in the ass. At times, it can feel like a complete miracle that any of them work at all. It's a cagey protocol with lots of inconsistencies and flaws, and with manufacturers of all stripes making sensors nowadays, you can imagine how Strava might be taking flak for poor quality control processes from some generic company overseas making millions of sensors for pennies on the dollar.`}</p>
    <p>{`Nonetheless, athletes `}<em parentName="p">{`really`}</em>{` miss having Heart Rate data in their workouts - the only workarounds available now are to use other mobile apps (which are often really bad), or to buy an expensive GPS watch or bike computer.`}</p>
    <p>{`People want a reverse chronological order for their timeline. This one's straight forward, and familiar if you've used pretty much any social network. Somewhere along the line, the powers that be (read: investors and shareholders) discovered that feed/timeline oriented apps can drive more profitable behavior if the timeline they show favors `}<em parentName="p">{`sticky`}</em>{` content (that is, content that is more likely to be engaged with, in whatever way is meaningful). Strava switched off their reverse-chronological feed at some point, and switched to an algorithmic one. That doesn't sit well with their users, who just want to see the latest from their friends.`}</p>
    <p>{`Certain social features are kind of lacking. For example: even though you can `}<em parentName="p">{`see`}</em>{` people who rode the same route as you at the same time as you, there's no way to contact them directly. That's a bummer! People are also jonesing for more social sharing functionality. Being able to show off a landmark run, hike, or ride is often distilled to a single still shot with some stats plastered on it, which only really scrapes the surface of the accomplishment that lies beneath.`}</p>
    <h3>{`What Strava is doing well`}</h3>
    <ul>
      <li parentName="ul">{`UX is far better than things like `}<a parentName="li" {...{
          "href": "https://trainingpeaks.com"
        }}>{`TrainingPeaks`}</a>{`, `}<a parentName="li" {...{
          "href": "https://www.trainerroad.com/"
        }}>{`TrainerRoad`}</a>{`, and `}<a parentName="li" {...{
          "href": "https://connect.garmin.com/"
        }}>{`Garmin Connect`}</a>{`. Those services either feel neglected or old, or (in the case of Garmin Connect) are just not targeted toward a social audience, so they're less sticky.`}</li>
      <li parentName="ul">{`The social aspect is really great. People really seem to like being able to see what their friends are up to... thus the frustrations with the non-chronological timeline noted above.`}</li>
      <li parentName="ul">{`Quantified self / statistics. This seems like a natural commonality amongst strava users. Mileage counts, kudos, KOMs, and PRs and segments are delicious, endorphin-generating tallies that keep athletes coming back for more.`}</li>
      <li parentName="ul">{`Finding places and routes to ride by looking at global heatmaps and local segments helps athletes explore outdoors, and feel like they're seeing more of the world than they might otherwise.`}</li>
      <li parentName="ul">{`"Social media without typical social media bullshit" - Strava is a network that posts one thing. No false articles, political nonsense, or scam buffoonery comes from someone posting their ride online.`}</li>
      <li parentName="ul">{`Social, social, social - this came up again and again. With physical exertion being the core of the content generation for Strava, it's no surprise that people love sharing what they've done. It's sharing without oversharing - it's being proud without being a humblebrag. That's pretty rare.`}</li>
    </ul>
    <h3>{`What's missing?`}</h3>
    <ul>
      <li parentName="ul">{`Creation and editing of routes. It seems like people are hungry for something like `}<a parentName="li" {...{
          "href": "https://ridewithgps.com"
        }}>{`RideWithGPS`}</a>{` or `}<a parentName="li" {...{
          "href": "https://komoot.com"
        }}>{`Komoot`}</a>{`, which are tools that help you `}<em parentName="li">{`plan`}</em>{` a ride before going out into the world.`}</li>
      <li parentName="ul">{`Exploration of segments - the more competitive athletes of strava want seemed to be interested in finding areas and routes where they might be able to capture a KOM`}</li>
      <li parentName="ul">{`Expansion of activity types! Several users mentioned that Strava doesn't seem to make space for "Walking" as a tracked activity.`}</li>
      <li parentName="ul">{`Experience parity between mobile / tablet apps and the website. Strava.com provides `}<strong parentName="li">{`loads`}</strong>{` more analysis than their mobile apps (perhaps by design), and it seems like athletes really notice this when using their phones and ipads.`}</li>
      <li parentName="ul">{`Direct messaging - it's a great feeling when you meet a new friend while out on a run or a ride, but there's no way to contact someone directly through any of Strava's interfaces. At best, you end up bugging someone in the comments section of an activity for personal contact info, which is not great.`}</li>
      <li parentName="ul">{`Once again, chronological feeds came up quite a bit here. Several people also mentioned a desire to be able to filter their feed (to show only running activities, for example)`}</li>
      <li parentName="ul">{`Customer support! Again, 50 Million users - your problems get lost in the heap very easily.`}</li>
    </ul>
    <h3>{`Value for Money`}</h3>
    <p>{`Those who pay for Strava's services on average rate value for money as 3.2 out of 5, with the majority of folks reporting 3 or above.`}</p>
    <Img src="value-for-money.png" figcaption="Although the average value-for-money was a 3, most paying users are happy with the money they're spending." alt="Chart showing distribution of survey results for Strava Value for Money" mdxType="Img" />
    <h3>{`What do you pay for other than Strava?`}</h3>
    <p><strong parentName="p">{`63%`}</strong>{` of respondants pay for more than just Strava, and `}<strong parentName="p">{`the vast majority of those pay for several other services`}</strong>{`. That's a good sign for making room for one more(!)`}</p>
    <h3>{`The \\$5 question - will they try something new?`}</h3>
    <p>{`Once again, the numbers are looking good! Of those who responded, the vast majority say they "Likely would" or "Definitely would" try out a new service. Here's hoping that holds true when we open up the flood gates on the MVP.`}</p>
    <Img src="try-new-service.png" figcaption={`63% of users would either "likely" or "definitely" try a new service. Oh, hello there! 😍`} alt={`Chart showing response to the survey question: "If you found a similar service to Strava today, how likely would you be to try it out?"`} mdxType="Img" />
    <h2>{`Conclusion`}</h2>
    <p>{`Well - I don't know that there's a concrete conclusion at this point, but I think this strengthens my feelings about the planned MVP. You should check that out on the `}<a parentName="p" {...{
        "href": "https://github.com/orgs/gopistola/projects/1"
      }}>{`Roadmap`}</a>{` - everything in the `}<inlineCode parentName="p">{`MVP`}</inlineCode>{` column is considered must-have in order to get the most basic version of Pistola out into the world.`}</p>
    <p>{`Want a sneak peek? I thought you might. As of this writing, the detail page looks like this:`}</p>
    <Img src="sneak-peek.png" figcaption="A taste of things to come." alt="Work in progress creenshot of a detail page for a recorded activity on Pistola" mdxType="Img" />
    <br />
    <center>
  👇 Make sure you <a href="#newsletter-signup">sign up for the newsletter</a>{' '}
  to get early access to Pistola! 👇
    </center>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      